// // アイコンフォント
// @mixin icomoon($_icon){
// 	font-family: icomoon;
// 	content: '' + map-get($iconMap, $_icon);
// 	font-weight: normal;
// }
//
// // 欧文
// @mixin english($_weight){
// 	font-family: 'Lato', sans-serif;
// 	font-style: normal;
// 	font-weight: $_weight;
// }

// Noto
@mixin notoSans{
	font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
}
// @mixin notoThin{
@mixin fontThin{
	@include notoSans();
	font-weight: 100;
}
// @mixin notoLight{
@mixin fontLight{
	@include notoSans();
	font-weight: 300;
}
// @mixin notoRegular{
@mixin fontRegular{
	@include notoSans();
	font-weight: 400;
}
// @mixin notoMedium{
@mixin fontMedium{
	@include notoSans();
	font-weight: 500;
	letter-spacing: 0.07em;
}
// @mixin notoBold{
@mixin fontBold{
	@include notoSans();
	font-weight: 600;
	letter-spacing: 0.07em;
}
// @mixin notoBlack{
@mixin fontBlack{
	@include notoSans();
	font-weight: 800;
	letter-spacing: 0.07em;
}




// 行数制限、越えた場合は「...」になる
@mixin line-num($lineHeight, $num, $flag: true){
	&{
		overflow: hidden;
		@if $flag{
			height: $lineHeight * $num;
		} @else {
			max-height: $lineHeight * $num;
		}

		// @supports (-webkit-box-orient : vertical) and (-webkit-line-clamp : 3) and (display : -webkit-box){
			display: -webkit-box;
			/* autoprefixer: off */
			-webkit-box-orient: vertical;
			/* autoprefixer: on */
			-webkit-line-clamp: $num;
			text-overflow: ellipsis;
		// }
	}
}


// hoverで画像をふわっと拡大する
@mixin imageHover($duration, $scale){
	figure{
		overflow: hidden;
		img{
			width: 100%;
			-webkit-backface-visibility: hidden;
			transition-duration: $duration;
		}
	}
	&:hover{
		figure{
			img{
				transform: scale($scale);
			}
		}
	}
}
