/* --------------------
 * footer
 * -------------------- */
#footer{
	//some style
	background-color: #444;

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		background-color: #ff3;
	}
}