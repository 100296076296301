@charset "UTF-8";

// @import '../../sass/vender/_normalize.scss';
@import '../../sass/vender/_reset.scss';
@import "/Users/inagaki/document/test0502/src/sass/lib/_mixin.scss";
@import "/Users/inagaki/document/test0502/src/sass/lib/_vars.scss";



// webfont
// Google Roboto
@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700');
// Google Loto
// @import url('//fonts.googleapis.com/css?family=Lato:100,300,400,700,900');
// Google Noto Fonts
@import url('//fonts.googleapis.com/earlyaccess/notosansjp.css');
// font-weight: 100; => Noto Sans JP Thin
// font-weight: 300; => Noto Sans JP Light
// font-weight: 400; => Noto Sans JP Regular
// font-weight: 500; => Noto Sans JP Medium
// font-weight: 600; => Noto Sans JP Bold
// font-weight: 800; => Noto Sans JP Black




/* font setting */
// Yu Gothic
// @import '../../sass/font/_yugothic.scss';
// icon font / icomoon
// @import '../../sass/font/_icon.scss';




/* default setting */
body{
	background-color: #ffffff;
	color: #222;
	@include fontRegular();
	// 	font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
	// 	font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif;
	font-size: 14px;
	line-height: 1.6;
	letter-spacing: 0.05em;
	-webkit-text-size-adjust: 100%;
}
a{
	color: rgb(20, 141, 240);
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}


// SP style
@media print, screen and (max-width: $breakpoint1 - 1){
	img{
		width: 100%;
		height: auto;
	}
	.pc{
		display: none;
	}
}
// PC style
@media print, screen and (min-width: $breakpoint1){
	.sp{
		display: none;
	}
}



/* components */
@import "/Users/inagaki/document/test0502/src/sass/components/_breadcrumb.scss";
@import "/Users/inagaki/document/test0502/src/sass/components/_footer.scss";
@import "/Users/inagaki/document/test0502/src/sass/components/_header.scss";

/* pages */

