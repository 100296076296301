/* --------------------
 * header
 * -------------------- */
#header{
	//some style
	background-color: #ccc;

	// PC style
	@media print, screen and (min-width: $breakpoint1){
		background-color: #f99;
	}
}